<template>
    <div class="main">
        <div class="content">
            <div class="fail">
                <!-- <img src="../../assets/images/close.png" /> -->
                <p class="text failtext1">Payment of ₹{{ amount }} Progress!</p>
                <p class="text failtext2">Expected Waiting time ≈ 60 sec</p>
                <p class="text failtext3">{{ curdate }}</p>
                <div style="padding-top: 50px;"></div>
                <button @click="orderResulet(true)" v-show="orderTimer.timerSeconds >= 50">Give Up Waiting</button>
                <p class="text failtext6">OrderID:{{ orderId }}</p>
            </div>

        </div>
        <div class="footer">
            <div class="footLogo">
                <p>Powered by</p>
                <img src="../../assets/images/upi.jpg">
            </div>

            <hr />

            <div class="paragraph">
                <p>Tips:</p>
                <p>lf your payment is successful and your account has not increased the amountplease prowide your order
                    number and
                    payment voucher to contact us assoon as possilble.</p>
            </div>
            <div class="mail">
                <i class="envelope"></i>
                {{ $config.SYS_AIOPAY_EMAIL }}
            </div>
        </div>

        <div class="loading-modal" v-show="showLoading">
            <div class="box loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>

        </div>

    </div>
</template>
<script>
import _validate from "@/utils/validate";
import {
    getPayOrderInfoByPayOrderId
} from '@/api/api'

import * as _cookie from '@/utils/cookie'


export default {
    data: function () {
        return {
            showLoading: true,
            dialogState: false,
            orderId: "",
            utr: "",
            amount: "0", // 支付金额默认
            resData: {},
            payOrderInfo: {}, //订单信息,
            singleTimer: null,
            curdate: '',
            paymentName: "Anonymous",
            orderTimer: {
                timeId: null,
                timerSeconds: 0,
                gapSeconds: 10
            }

        };
    },
    created() {
        this.$moment().locale('en')
        this.curdate = this.$moment().format('HH:mm A ,DD MMMM YYYY');

        this.payOrderInfo = _cookie.getPayOrderData();
        //跳转走
        if (_validate.empty(this.payOrderInfo)) {

            return;
        }
        this.amount = this.payOrderInfo.am
        if (!_validate.empty(this.$route.query.orderId)) {
            this.orderId = this.$route.query.orderId;
        }
    },
    mounted() {
        // this.payment(false); //获取订单信息 & 调起支付插件
        this.beginOrderSingleTimerSeconds()
    },

    methods: {
        beginOrderSingleTimerSeconds() {
            const _this = this
            console.log('succ beginSingleTimerSeconds');
            if (_this.orderTimer.timeId === null) {
                _this.orderTimer.timeId = setInterval(() => {
                    _this.orderResulet();
                    _this.orderTimer.timerSeconds += _this.orderTimer.gapSeconds;
                    console.log('succ beginSingleTimerSeconds : ' + _this.orderTimer.timerSeconds);
                }, (_this.orderTimer.gapSeconds * 1000))
            }
        },
        orderResulet(isAt = false) {
            const _this = this

            getPayOrderInfoByPayOrderId(_this.orderId).then(res => {
               
                //订单支付成功
                if (res.state === 2) {
                    _this.clearSignleTimerId()
                    //   //跳转订单支付succ页面
                    _this.$router.push({
                        name: 'CashierPayResultSuccess',
                        query: {
                            orderId: _this.orderId,
                            utr: res.referenceId !== undefined ? res.referenceId : '',
                            am: res.amt
                        }
                    })
                    return;
                }
                //支付失败
                if ([3, 4, 6].includes(res.state) || _this.orderTimer.timerSeconds >= 60) {
                    _this.clearSignleTimerId()
                    //   //跳转订单支付succ页面
                    _this.$router.push({
                        name: 'CashierPayResultFail',
                        query: {
                            errInfo: 'Order Payment Fail',
                            orderId: _this.orderId,
                            am: _this.payOrderInfo.am
                        }
                    })
                    return;
                }
                //手动最后一次检测 之后直接跳转
                if (isAt === true) {
                    _this.orderTimer.timerSeconds = 60;
                }

                //如果定时器超出 1 分钟 默认跳转支付失败页面
                // if (_this.orderTimer.timerSeconds >= 60) {
                // }

            })
            console.log('succ orderResulet');

        },
        clearSignleTimerId() {
            if (this.orderTimer.timeId !== null) {
                clearInterval(this.orderTimer.timeId)
                this.orderTimer.timeId = null
            }
        }
    }
}

</script>
<style>
body,
html {
    padding: 0 !important;
    margin: 0 !important;
}
</style>
<style scoped>
.main {
    background-color: #eee;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    padding: .2rem;
    padding-top: 1rem;
}

.content .success,
.content .fail {
    border-radius: .6rem;
    background-color: #fff;
    padding: .2rem;
    padding-bottom: 1rem;
}

.content .success .ok {
    border-radius: .6rem;
    background-color: rgb(236, 251, 244);
    font-size: 1rem;
    font-family: 黑体;
    font-weight: 600;
    padding-top: 2rem;
    padding-bottom: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

}

.content .success .ok p {
    margin: 0;
}

.content .success .ok p.msg {
    font-size: .6rem;
    margin-top: .2rem;
}

.content .success .ok p.money {
    background: url(../../assets/images/payment_success.jpg) no-repeat calc(50% + 1.4rem) center;
    background-size: 1rem;
    padding-right: 1rem;
}

.text {
    font-size: .2rem;
}

.text.text1 {
    margin-top: 1.4rem;
    font-size: .5rem;
    font-weight: 600;
}

.text.text2 {
    font-size: .4rem;
    margin-top: .6rem;
}

.text.text2,
.text.text3 {
    font-size: .45rem;
    color: rgb(165, 165, 165);
}

.text.text4 {
    margin-top: .6rem;
    font-weight: 600;
    font-size: .4rem;
}



/* fail */
.content .fail>img {
    width: 1.4rem;
    margin-top: 1.6rem;
}


.content .fail .failtext1 {
    font-weight: 600;
    font-size: .6rem;
    margin-bottom: 0;
}


.content .fail .failtext2 {
    margin-top: .2rem;
    font-size: .4rem;
    font-weight: 600;
}

.content .fail .failtext3 {
    font-size: .4rem;
    color: rgb(165, 165, 165);
}

.content .fail .failtext4 {
    font-weight: 600;
    font-size: .4rem;
}

.content .fail .failtext5 {
    font-weight: 600;
    font-size: .4rem;
}

.content .fail .failtext6 {
    font-size: .4rem;
    color: rgb(165, 165, 165);
}

.content .fail>button {
    background-color: rgb(54, 122, 247);
    color: #fff;
    font-size: .4rem;
    border: none;
    border-radius: 0.5rem;
    display: block;
    width: 4rem;
    height: 1.2rem;
    margin: 0.6rem auto;
}

.content .fail>hr {
    width: 1rem;
    margin-top: 1.6rem;
}



.footer {
    width: 100%;
    font-size: .4rem;
    background-color: #eee;
    padding-bottom: 3rem;
    /* margin-top: 1rem; */
}

.footer hr {
    margin: .2rem .6rem;
    border: 1px solid #fff;
}

.footer .footLogo {
    text-align: center;
}

.footer .footLogo p {
    margin: 0;
    color: #5d5d5d;
    font-size: .2rem;

}

.footer .footLogo img {
    width: 1.4rem;
    height: auto;
    margin-top: .2rem;
}

.footer .paragraph {
    text-align: left;
    padding: 0 .6rem;
    font-size: .2rem;
    color: #939393;
}

.footer .mail {
    text-align: left;
    padding: 0 0.6rem;
    font-size: .2rem;
}

.envelope::after {
    font-family: fontAwesome;
    content: "\f0e0";
    font-weight: normal;
    font-style: normal;
    font-size: .4rem;
}
</style>

<style scoped>
.loading-modal {
    position: fixed;
    top: 5.5rem;
    left: 0;
    width: 100vw;
    /* height: 80%; */

    /* height: 100vh; */
    /* background-color: #55555540; */
}

.loading {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.loading span {
    display: block;
    width: 20px;
    height: 20px;
    background: #000;
    border-radius: 50%;
    animation: effect .6s linear infinite alternate;
    transform: scale(0);
    background: #002b73;
}

@keyframes effect {
    100% {
        transform: scale(1);
    }
}

.loading span:nth-child(1) {
    animation-delay: .1s;
}

.loading span:nth-child(2) {
    animation-delay: .2s;
}

.loading span:nth-child(3) {
    animation-delay: .3s;
}

.loading span:nth-child(4) {
    animation-delay: .4s;
    background: #00b9f5;
}

.loading span:nth-child(5) {
    animation-delay: .5s;
    background: #00b9f5;
}
</style>